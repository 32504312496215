<template>
  <div class="bgc">
    <div class="nav">
      <div @click="handleback()">
        <i class="el-icon-arrow-left"></i>
        <p>上传/下载明细</p>
      </div>
      <div class="sel">
        <div>
          <el-select v-model="value" placeholder="店铺选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div>
          <el-select v-model="values" placeholder="上传">
            <el-option v-for="item in optionss" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div>
          <el-date-picker v-model="value1" type="date" placeholder="日期选择">
          </el-date-picker>
        </div>
      </div>

    </div>
    <div class="table">
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="caozuo" label="操作" width="360">
        </el-table-column>
        <el-table-column prop="shop" label="分店" width="360">
        </el-table-column>
        <el-table-column prop="bumen" label="部门" width="360">
        </el-table-column>
         <el-table-column prop="renyuan" label="人员" width="360">
        </el-table-column>
         <el-table-column prop="time" label="时间" >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      optionss: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      tableData: [{
          caozuo:'上传',
          shop:'望京店',
          time: '2016-05-02',
          renyuan: '王小虎',
          bumen: '事业部'
        }, {
           caozuo:'上传',
          shop:'望京店',
          time: '2016-05-02',
          renyuan: '王小虎',
          bumen: '事业部'
        }, {
         caozuo:'上传',
          shop:'望京店',
          time: '2016-05-02',
          renyuan: '王小虎',
          bumen: '事业部'
        }, {
          caozuo:'上传',
          shop:'望京店',
          time: '2016-05-02',
          renyuan: '王小虎',
          bumen: '事业部'
        }],
      value1: '',
      value: '',
      values: ''
    }
  },
  methods: {
    handleback () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.table{
  margin-top: 50px;
}
.bgc {
  width: 100%;
  height: 100%;
  .sel {
    display: flex;
    div {
      margin-left: 20px;
    }
  }
  .nav {
    display: flex;
    justify-content: space-between;
    div {
      &:first-of-type {
        display: flex;
      }
    }
    p,
    i {
      font-size: 18px;
    }
  }
}
</style>